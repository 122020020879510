<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-0 pb-0'>
                    <div class='align-items-center justify-content-start mb-0 md:mb-0'>
                        <img src='/layout/images/icon/yemek_masasi.svg' height='72' class='mt-2 mr-2' />
                        <h3 class='mb-1 mt-2'>Yeni Proje Ekle</h3>
                    </div>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true'
                        class='p-button-danger mr-2 p-2 pl-3 pr-3' />

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Proje Ekleme</h3>

					<p class='text-lg'>
                        <b>Proje Marka Adı</b><br>
                        Proje adının kısaltılmışı yazılmalıdır.<br>
                        <br>
                        <b>Firma Yetkilisi</b><br>
                        Firma yetkili adı, soyadı, gsm ve e-posta bilgileri zorunludur. Firma yetkilisi mutabakat yapılan kişidir.<br>
                        <br>
                        <b>Proje Başlangıç</b><br>
                        Proje başlangıç tarihi ilk servis verilen gün olarak girilmelidir.<br>
                        <br>

                        <span class='text-red-500 font-bold ml-2 mr-2'>*</span> işaretli alanlar zorunlu alanlardır.<br>
					</p>

				</Sidebar>

				</span>


            </div>

        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='col-12'>

                    <div class='field grid mb-5'>
                        <label for='name' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Marka Adı<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='name' type='text' autocomplete='off' v-model='dataItem.name' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='phone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Telefon</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='phone' type='text' autocomplete='off' v-model='dataItem.phone'
                                       maxlength='10' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='email' class='col-12 mb-2 md:col-2 md:mb-0'>Firma E-Posta</label>
                        <div class='col-12 md:col-10'>
                            <InputText id='email' type='text' autocomplete='off' v-model='dataItem.email' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personName' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Yetkilisi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personName' type='text' autocomplete='off' v-model='dataItem.personName' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personPhone' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Yetkili GSM<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personPhone' type='text' autocomplete='off' maxlength='10'
                                       v-model='dataItem.personPhone' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='personEmail' class='col-12 mb-2 md:col-2 md:mb-0'>Firma Yetkili E-Posta<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <InputText id='personEmail' type='text' autocomplete='off' v-model='dataItem.personEmail' />
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='startDate' class='col-12 mb-2 md:col-2 md:mb-0'>Proje Başlangıç Tarihi<span
                            class='text-red-500 font-bold ml-2 mr-2'>*</span></label>
                        <div class='col-12 md:col-10'>
                            <Calendar id='startDate' :showIcon='true' autocomplete='off' dateFormat='dd.mm.yy'
                                      :showButtonBar='true' v-model='dataItem.startDate'></Calendar>
                        </div>
                    </div>

                    <div class='field grid mb-5'>
                        <label for='isActive' class='col-12 mb-2 md:col-2 md:mb-0'>Durum</label>
                        <div class='col-12 md:col-10'>
                            <InputSwitch id='isActive' v-model='dataItem.isActive' />
                        </div>
                    </div>


                    <div class='field grid'>
                        <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                        <div class='col-12 md:col-10'>
                            <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'
                                    this.isProcessing='true;'></Button>
                        </div>
                    </div>


                </div>

            </div>

        </div>


    </div>
</template>

<script>


import { showErrorMessage, showValidationMessage } from '../../components/shared/toast/toastFunction';
import ProjectService from '../../services/projectService';
import AdminToast from '@/components/shared/toast/adminToast';

export default {
    components: { AdminToast },
    _projectService: null,
    created() {
        this._projectService = new ProjectService();
    },
    data() {
        return {
            isProcessing: true,
            dataItem: {
                name: '',
                isActive: true,
                email: '',
                phone: '',
                startDate: '',
                endDate: '',
                personName: '',
                personPhone: '',
                personEmail: '',
            },

            display: false,
            visibleLeft: false,
        };
    },
    methods: {
        async save() {
            this.isProcessing = true;
            if (this.validateForm()) {
                const insertResponse = await this._projectService.insertProject(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this.$router.push({ name: 'editProject', params: { projectId: insertResponse.data } });
                } else {
                    showErrorMessage(this, 'Proje Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        validateForm() {
            if (this.dataItem.name == '') {
                showValidationMessage(this, 'Proje Marka Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personName == '') {
                showValidationMessage(this, 'Firma Yetkilisi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personPhone == '') {
                showValidationMessage(this, 'Firma Yetkili GSM Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.personEmail == '') {
                showValidationMessage(this, 'Yetkili E-Posta Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.startDate == '') {
                showValidationMessage(this, 'Başlangıç Tarihi Alanı Boş Bırakılamaz');
                return false;
            }


            return true;
        },
    },
};

</script>


<style scoped>

</style>
